:root {
  /* Base */
  --white: #ffffff;
  --black: #000000;
  /* --> Primary */
  --green-050: #d2e7c6;
  --green-100: #b5d8a2;
  --green-200: #8bc665;
  --green-300: #65a34c;
  --green-400: #4c9540;
  --green-500: #4c9540;
  --green-600: #3d7b34;
  --green-700: #2e6227;
  --green-800: #1f491a;
  --green-900: #0f3010;
  --green-1000: #001a00;

  /* Dark mode */
  --dark: var(--green-900);
  --light: var(--green-100);
  color-scheme: light dark;

  /* Surface */
  --surface-dark: var(--dark);
  --surface-light: var(--light);

  /* Action */

  /* --> Surface */
  --action-primary-light: var(--dark);
  --action-secondary-light: transparent;
  --action-tertiary-light: transparent;
  --action-primary-dark: var(--light);
  --action-secondary-dark: transparent;
  --action-tertiary-dark: transparent;

  --action-primary-hover-light: var(--green-700);
  --action-primary-hover-dark: var(--green-200);
  --action-secondary-hover-light: var(--green-200);
  --action-secondary-hover-dark: var(--green-800);
  --action-tertiary-hover-light: var(--green-200);
  --action-tertiary-hover-dark: var(--green-800);

  /* --> Text */
  --action-primary-text-light: var(--light);
  --action-secondary-text-light: var(--dark);
  --action-tertiary-text-light: var(--dark);

  --action-primary-text-dark: var(--dark);
  --action-secondary-text-dark: var(--light);
  --action-tertiary-text-dark: var(--light);

  /* --> Border */
  --action-secondary-border-light: var(--dark);
  --action-secondary-border-dark: var(--light);
}

header,
div,
footer {
  box-sizing: border-box;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  font-size: 14px;

  font-family: sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "wdth" 100;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;

  background: light-dark(var(--surface-light), var(--surface-dark));
  color: light-dark(var(--dark), var(--light));
  width: 100vw;
  height: 100%;
}

@media (min-width: 640px) {
  body {
    font-size: 16px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-scroll {
  overflow: hidden;
}
